<script setup lang="ts">
import { computed } from 'vue';
import { useRoute, useRouter, type RouteLocation } from 'vue-router';
import {
  FhDropdownMenu,
  FhDropdownSection,
  FhDropdownSingleSelectItem,
} from '@fareharbor-com/beacon-vue';
import { ChevronDownIcon } from '@heroicons/vue/24/solid';
import type { DropdownMenuItem } from './types';
import { useCompanyNavigationMenuItemClasses } from './useCompanyNavigationMenuItemClasses';

interface CompanyNavigationMenuDropdownItemProps {
  items: DropdownMenuItem[];
}

const props = defineProps<CompanyNavigationMenuDropdownItemProps>();

const route = useRoute();
const router = useRouter();
const selectedPath = computed({
  get: () => route.path,
  set: () => {},
});
const isActive = computed(() =>
  props.items.some((item) => route.path.startsWith(item.to.path)),
);

const { dropdownMenuClasses } = useCompanyNavigationMenuItemClasses();

const handleNavigation = (to: RouteLocation) => {
  router.push(to);
};
</script>

<template>
  <FhDropdownMenu>
    <template #default>
      <button :class="dropdownMenuClasses(isActive)">
        <slot />
        <ChevronDownIcon class="w-4 h-4 inline" />
      </button>
    </template>
    <template #sections>
      <FhDropdownSection type="single-select" v-model="selectedPath">
        <FhDropdownSingleSelectItem
          v-for="(item, index) in items"
          :key="index"
          :value="item.to.path"
          @select="() => handleNavigation(item.to)"
        >
          {{ item.label }}
        </FhDropdownSingleSelectItem>
      </FhDropdownSection>
    </template>
  </FhDropdownMenu>
</template>
