<script setup lang="ts">
import { RouterView } from 'vue-router';
import AppErrorState from '@/components/AppErrorState.vue';
import AppLoadingState from '@/components/AppLoadingState.vue';
import DemoBanner from '@/components/DemoBanner.vue';
import MainHeader from '@/components/MainHeader.vue';
import ToastContainer from '@/components/ToastContainer.vue';
import DeskPage from '@/components/shared/DeskPage/DeskPage.vue';
import { useAppStore } from '@/stores/useAppStore';

const appStore = useAppStore();
</script>

<template>
  <DemoBanner v-if="appStore.isDemoEnvironment" />
  <AppLoadingState v-if="appStore.isLoading" />
  <AppErrorState v-if="appStore.isError" />
  <main v-if="appStore.isReady" class="flex flex-col h-screen">
    <DeskPage class="py-3">
      <MainHeader />
    </DeskPage>
    <div class="grow">
      <RouterView class="h-full" />
    </div>
  </main>
  <ToastContainer />
</template>
