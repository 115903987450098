import { defineStore } from 'pinia';
import { getCompany } from '@/common/api/getCompany';
import { APIError } from '@/common/errors/APIError';
import { ErrorWithContext } from '@/common/errors/ErrorWithContext';
import { handleThrownError } from '@/common/errors/handleThrownError';
import type { CompanySchema, CompanySettingsSchema } from '@/types';

interface CompanyStore {
  shortname?: string;
  currentDesk?: {
    deskId: string | null;
    deskName: string | null;
  };
  currentCompany?: CompanySchema;
  isLoading: boolean;
}

interface CompanyStoreLoadCompanyConfig {
  force?: boolean;
  throwError?: boolean;
}

export const useCompanyStore = defineStore('companyStore', {
  state: (): CompanyStore => ({
    shortname: undefined,
    currentCompany: undefined,
    currentDesk: undefined,
    isLoading: false,
  }),
  getters: {
    companyTimezone: (state): string => {
      if (!state.currentCompany) {
        throw new ErrorWithContext('useCompanyStore: company not loaded');
      }
      // TODO: Make it so that information of CompanySchema is non-nullable
      return state.currentCompany.information?.timezone ?? 'Etc/UTC';
    },
    company: (state): CompanySchema => {
      if (!state.currentCompany) {
        throw new ErrorWithContext('useCompanyStore: company not loaded');
      }
      return state.currentCompany;
    },
    isAffiliatesEnabled: (state) => {
      if (!state.currentCompany) {
        throw new ErrorWithContext('useCompanyStore: company not loaded');
      }
      return (
        state.currentCompany.settings.affiliate?.is_affiliate_enabled ?? false
      );
    },
  },
  actions: {
    isCompanyLoaded(shortname: string) {
      return this.currentCompany && this.shortname === shortname;
    },
    setCurrentDesk(deskId: string, deskName: string): void {
      this.currentDesk = {
        deskId,
        deskName,
      };
    },
    async loadCompany(
      shortname: string,
      config?: CompanyStoreLoadCompanyConfig,
    ) {
      if (this.isCompanyLoaded(shortname) && !config?.force) {
        return;
      }
      try {
        this.isLoading = true;
        const { status, data, error } = await getCompany(shortname);
        if (status !== 200 || !data) {
          throw new APIError({
            message: 'useCompanyStore: Failed to load Company',
            status,
            error,
          });
        }
        this.shortname = shortname;
        this.currentCompany = data;
      } catch (error: unknown) {
        handleThrownError({
          error,
          toastMessage: 'Failed to load company information',
          throw: config?.throwError,
        });
      } finally {
        this.isLoading = false;
      }
    },
    updateCompanySettings(settings: Partial<CompanySettingsSchema>) {
      if (!this.currentCompany) {
        throw new ErrorWithContext('useCompanyStore: company not loaded');
      }
      this.currentCompany.settings = {
        ...this.currentCompany.settings,
        ...settings,
      };
    },
  },
});
