import { defineStore } from 'pinia';
import { getProduct } from '@/common/api/getProduct';
import { getProducts } from '@/common/api/getProducts';
import { APIError } from '@/common/errors/APIError';
import { handleThrownError } from '@/common/errors/handleThrownError';
import type { ProductSchema } from '@/types';
import type { FhSelectOption } from '@/types/FhSelectOption';

const transformData = (data: ProductSchema[]): FhSelectOption[] =>
  data.map((product) => ({
    label: product.name,
    value: product.id as string,
  }));

interface ProductStoreState {
  products: FhSelectOption[];
  loadedProduct?: ProductSchema;
}

export const useProductStore = defineStore('productStore', {
  state: (): ProductStoreState => ({
    products: [],
    loadedProduct: undefined,
  }),
  getters: {
    isOnlineProduct: (state) =>
      !!(
        state.loadedProduct?.external_id && state.loadedProduct?.external_source
      ),
  },
  actions: {
    async loadProducts(shortname: string, vendorId: string) {
      try {
        const { status, data, error } = await getProducts(shortname, vendorId);
        if (status !== 200 || !data) {
          throw new APIError({
            message: 'Failed to load Products',
            status,
            error,
            context: {
              vendorId,
            },
          });
        }
        this.products = transformData(data.entities);
      } catch (error: unknown) {
        handleThrownError({
          error,
          toastMessage: 'Failed to load Products',
        });
      }
    },

    async loadProduct(shortname: string, productId: string) {
      try {
        const { status, data, error } = await getProduct(shortname, productId);

        if (status !== 200 || !data || error) {
          throw new APIError({
            message: 'Failed to load Product',
            status,
            error,
            context: {
              productId,
            },
          });
        }
        this.loadedProduct = data;
      } catch (error: unknown) {
        handleThrownError({
          error,
          toastMessage: 'Failed to load Product',
        });
      }
    },
  },
});
