import { defineStore } from 'pinia';
import { getAllVendors } from '@/common/api/getAllVendors';
import { getVendor } from '@/common/api/getVendor';
import { APIError } from '@/common/errors/APIError';
import { handleThrownError } from '@/common/errors/handleThrownError';
import type { VendorSchema } from '@/types';
import type { FhSelectOption } from '@/types/FhSelectOption';

type VendorOption = FhSelectOption & { id: string };

interface VendorStoreState {
  vendors: VendorOption[];
  areVendorsLoaded: boolean;
  loadedVendor?: VendorSchema;
}

const transformData = (data: VendorSchema[]): VendorOption[] =>
  data.map((vendor) => ({
    id: vendor.id,
    label: vendor.name,
    value: vendor.id,
  }));

export const useVendorStore = defineStore('vendorStore', {
  state: (): VendorStoreState => ({
    vendors: [],
    areVendorsLoaded: false,
    loadedVendor: undefined,
  }),
  actions: {
    async loadVendors(shortname: string) {
      this.vendors.splice(0);
      try {
        const { status, data, error } = await getAllVendors(shortname);
        if (status !== 200 || !data) {
          throw new APIError({
            message: 'Failed to load Vendors',
            status,
            error,
          });
        }
        this.vendors.push(...transformData(data));
        this.areVendorsLoaded = true;
      } catch (error: unknown) {
        handleThrownError({
          error,
          toastMessage: 'Failed to load Vendors',
        });
      }
    },
    async loadVendor(shortname: string, vendorId: string) {
      try {
        const { status, data, error } = await getVendor(shortname, vendorId);
        if (status !== 200 || !data || error) {
          throw new APIError({
            message: 'Failed to load Vendor',
            status,
            error,
            context: {
              vendorId,
            },
          });
        }
        this.loadedVendor = data;
      } catch (error: unknown) {
        handleThrownError({
          error,
          toastMessage: 'Failed to load Vendor',
        });
      }
    },
  },
});
